import clsx from "clsx";
import React from "react";
import { validateLanguage } from "~/utils/language";
import { BlogCard } from "~/components/blog/blog-card";
import type { Post, BlogCardVariant } from "~/components/blog/blog-card";
import { Spacer } from "~/components/ui/spacer";
import { Newsletter } from "~/components/newsletter";
import { Button } from "~/components/ui/button";
import { spTrackWebInteraction } from "~/utils/tracking";

interface Props {
	posts: Post[];
	lang: string;
	loadMoreText?: string;
	pageSize?: number;
	onLoadMore?: (indexToShow: number) => void;
	indexToShow?: number;
	initialIndexToShow?: number;
	variant?: "default" | "emphasized";
	showNewsletter?: boolean;
}

const PAGE_SIZE = 9;

export function BlogGrid({
	posts,
	lang,
	loadMoreText = "Load more",
	initialIndexToShow = PAGE_SIZE,
	pageSize = PAGE_SIZE,
	indexToShow: controlledIndexToShow,
	variant = "default",
	onLoadMore,
	showNewsletter = false,
}: Props) {
	const [indexToShow, setIndexToShow] = React.useState(initialIndexToShow);

	// Check if the indexToShow state is controlled by the parent component
	// if not, we will handle its state internally
	const indexToShowIsControlled = controlledIndexToShow !== undefined;
	const visibleIndex = indexToShowIsControlled
		? controlledIndexToShow
		: indexToShow;

	const visiblePosts = posts.slice(0, visibleIndex);
	const hasMorePosts = visiblePosts.length < posts.length;

	function loadMore() {
		if (onLoadMore) {
			hasMorePosts
				? onLoadMore(visibleIndex + pageSize)
				: onLoadMore(visibleIndex);
		}

		if (hasMorePosts && !indexToShowIsControlled) {
			setIndexToShow((index) => index + pageSize);
		}

		if (hasMorePosts) {
			spTrackWebInteraction({
				object: "load more",
				action: "show",
				value: `${Math.min(visibleIndex + pageSize, posts.length)} posts`,
			});
		}
	}

	return (
		<div>
			<ul className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
				{visiblePosts.map((post, index) => {
					let cardVariant: BlogCardVariant =
						index === 0
							? "emphasized"
							: index === 1 || index === 2
								? "simplified"
								: "default";

					if (variant === "default") {
						cardVariant = "default";
					}

					const hasSubscribeNewsletterSectionAfter = index === 2;

					return (
						<React.Fragment key={post.id}>
							<li
								className={clsx("h-full w-full", {
									"md:col-span-full lg:col-span-2 lg:row-span-2":
										cardVariant === "emphasized",
								})}
							>
								<BlogCard
									post={post}
									variant={cardVariant}
									lang={validateLanguage(lang) ? lang : undefined}
								/>
							</li>
							{showNewsletter && hasSubscribeNewsletterSectionAfter && (
								<li className="col-span-full">
									<Newsletter />
								</li>
							)}
						</React.Fragment>
					);
				})}
			</ul>
			{hasMorePosts ? (
				<>
					<Spacer size="layout4" />
					<div className="flex justify-center">
						<Button variant="secondary" onClick={loadMore}>
							{loadMoreText}
						</Button>
					</div>
				</>
			) : null}
		</div>
	);
}
